<template>
  <div class="manage-project-sales-reservation__reserved-units">
    <div class="container fluid">
      <reserved-units
        manageReservationRouteName="ManageProjectReservations"
        :getReservedUnitsAPI="reservationAPI.getReservedUnits"
      ></reserved-units>
    </div>
  </div>
</template>

<script>
import ReservedUnits from "@/components/GlobalComponents/Shared/ProjectSales/Reservation/ReservedUnits";
import { projectReservation as reservationAPI } from "@/api";

export default {
  components: {
    ReservedUnits
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return { reservationAPI };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>

